import { GKCard } from "@granular/gds";
import React from "react";
import { useAtomValue } from "jotai";
import { FeedbackBtn } from "../Feedback";
import { chatIdAtom } from "../state/state";
import styles from "./ChatMessage.module.css";
import { FileMessage } from "./FileMessage";

export type ChatData = {
  message: string;
  userEntered: boolean;
  isFile: boolean;
  hidden: boolean;
};

type ChatMessageProp = {
  data: ChatData;
  showFeedbackBtns: boolean;
};

const RenderMarkdown = React.lazy(() => import("../RenderMarkdown"));

export const ChatMessage: React.FunctionComponent<ChatMessageProp> = ({
  data,
  showFeedbackBtns,
}) => {
  const chatId = useAtomValue(chatIdAtom);

  if (data.hidden) {
    return;
  }

  const baseClass = data.userEntered
    ? styles.userMessage
    : styles.systemMessage;

  if (data.isFile) {
    return <FileMessage filename={data.message} />;
  }

  return (
    <GKCard className={`${styles.chatMessage} ${baseClass}`}>
      {data.userEntered ? (
        data.message
      ) : (
        <>
          <RenderMarkdown data={data.message} />
          {showFeedbackBtns && chatId && <FeedbackBtn chatId={chatId} />}
        </>
      )}
    </GKCard>
  );
};
