import { getCurrentToken } from "@granular/fabric3-core";

type DeleteFileProps = {
  chatId: string;
  fileName: string;
};

export const deleteFile = async ({ chatId, fileName }: DeleteFileProps) => {
  const accessToken = getCurrentToken();
  const resp = await fetch(
    `/api/chatbot-svc/chat/delete_file?chatId=${chatId}&fileName=${fileName}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      method: "DELETE",
    },
  );
  return resp;
};
