import { GKCard, GKIcon, GKIconName } from "@granular/gds";
import { useIntl } from "react-intl";
import DeleteFile from "../DeleteFile";
import ImagePreview from "../ImagePreview/ImagePreview";
import styles from "./ChatMessage.module.css";

type FileMessageProp = {
  filename: string;
};

export const FileMessage: React.FunctionComponent<FileMessageProp> = ({
  filename,
}) => {
  const intl = useIntl();
  const isImage = filename.match(/\.(jpeg|jpg|png)$/) !== null;
  return (
    <GKCard className={`${styles.chatMessage} ${styles.userMessage}`}>
      <div className={styles.uploadHeader}>
        <div className={styles.fileIcon}>
          <GKIcon name={GKIconName.File} />
          <span className={styles.uploadTitle}>
            {intl.formatMessage({ id: "uploaded_file" })}
          </span>
        </div>
        <DeleteFile fileName={filename} />
      </div>
      {isImage && (
        <div>
          <ImagePreview fileName={filename} />
        </div>
      )}
    </GKCard>
  );
};
