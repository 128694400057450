/**
 * This is the default index of the Container. It's the 'app' at the root ("/")
 * path. It is expected that we'll redirect this to some other path as we
 * evolve things in the monorepo.
 */

import { useState, type FC } from "react";
import { coreQueryClient } from "@granular/fabric3-core";
import ContextSwitcher from "./components/ContextSwitcher";
import { useTimeout } from "@granular/fabric3-core";

const opacity = 0.5;

const Hello: FC = () => {
  const { isSuccess, data } = coreQueryClient.user.profile.useQuery();
  const [waitedForLoading, setWaitedForLoading] = useState(false);
  useTimeout(() => setWaitedForLoading(true), 750);
  if (!waitedForLoading) {
    return (
      <div>
        <span role="img" aria-label="waving hello" className="pr-3">
          👋
        </span>
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center justify-content-center p-5 h-100 bg-danger text-light">
      <div>
        <section className="pr-5">
          <h1>
            Hello<strong>{isSuccess && ` ${data.firstName}`}</strong>!{" "}
            <span role="img" aria-label="waving hello" className="pr-3">
              👋
            </span>
            <br />
            This is our new Fabric3 Container.
            <br />
            <small
              style={{
                opacity,
              }}
            >
              v{import.meta.env.VITE_FABRIC3_PACKAGE_VERSION} build{" "}
              {import.meta.env.VITE_FABRIC3_SHORTHASH}
            </small>
          </h1>
          <p
            style={{
              opacity,
            }}
          >
            Every app will be lazy-loaded into this one.
            <br />I don&apos;t do anything. I&apos;m just a placeholder for some
            default root application.
          </p>
        </section>
        <hr />

        <h3>Pick a Context to work on.</h3>
        <p
          style={{
            opacity,
          }}
        >
          Note: This is for Local Development only.
        </p>
        <ContextSwitcher />
      </div>
    </div>
  );
};

export default Hello;
