// These schemas will eventually come from a TS client generated from zones-service, but
// that doesn't exist yet.
import { z as zod } from "zod";

export const fieldLayerReference = zod.object({
  fieldLayerId: zod.string().uuid(),
  layerType: zod.string(),
});

export const yieldGoal = zod.object({
  unitOfMeasure: zod.string(),
  dry: zod.number(),
  dryMin: zod.number().nullish(),
  dryMax: zod.number().nullish(),
  irrigated: zod.number().nullish(),
  irrigatedMin: zod.number().nullish(),
  irrigatedMax: zod.number().nullish(),
});

export const zoneSetInfo = zod.object({
  zoneSetId: zod.string().uuid(),
  active: zod.boolean(),
  yieldGoal: yieldGoal.nullish(),
  cropSeasonId: zod.string().uuid(),
});

export const zoneSetInfoByCropSeason = zod.record(
  zod.string().uuid(),
  zoneSetInfo,
);

export const zoneSet = zod.object({
  zoneSetId: zod.string().uuid(),
  zoneSetRevisionId: zod.string().uuid(),
  operationId: zod.string().uuid(),
  fieldId: zod.string().uuid(),
  name: zod.string(),
  soilLayer: fieldLayerReference.nullable(),
  productivityLayer: fieldLayerReference,
  otherFieldLayers: zod.array(fieldLayerReference),
  preparedFieldLayers: zod.array(fieldLayerReference),
  createdOn: zod.string(),
  modifiedOn: zod.string(),
  businessCycleId: zod.string().uuid().nullable(),
  rollOverSourceZs: zod.string().uuid().nullable(),
  minPolygonSize: zod.number().nullable(),
  zoneSetInfo: zoneSetInfoByCropSeason.nullable(),
});

export const getV4ZoneSetsResponse = zod.array(zoneSet);

export const generateDecisionZonesFromSmartDefaultsResponse = zod.object({
  commandIds: zod.array(zod.string()),
});

export const setActiveZoneSetResponse = zod.object({
  commandId: zod.string(),
  result: zod.object({
    fieldId: zod.string(),
    zoneSetId: zod.string(),
    productionCycleId: zod.string(), // Not actually used, just here to complete the schema
  }),
});

export const updateYieldGoalsV4PayloadItem = zod.object({
  fieldId: zod.string().uuid(),
  cropSeasonId: zod.string().uuid(),
  useActive: zod.boolean(),
  yieldGoal,
  zoneSetId: zod.string().uuid(),
  useSmartDefaults: zod.boolean(),
});
export const updateYieldGoalsV4Payload = zod.array(
  updateYieldGoalsV4PayloadItem,
);
export const updateYieldGoalsV4Response = zod.object({
  command_id: zod.string(),
});

export const zonesPollResult = zod.object({
  createdBy: zod.string(),
  commandId: zod.string(),
  parentCommandId: zod.string().nullable(),
  optionalCorrelationId: zod.string().nullable(),
  status: zod.string(),
});

export const zonesGetStatusForCommandsResponse = zod.array(zonesPollResult);

export const vrsYieldGoal = zod.object({
  operationId: zod.string().uuid(),
  fieldId: zod.string().uuid(),
  cropSeasonId: zod.string().uuid(),
  unitOfMeasure: zod.string().nullable(),
  yieldGoalDry: zod.number().nullable(),
  yieldGoalDryMin: zod.number().nullable(),
  yieldGoalDryMax: zod.number().nullable(),
  yieldGoalIrrigated: zod.number().nullable(),
  yieldGoalIrrigatedMin: zod.number().nullable(),
  yieldGoalIrrigatedMax: zod.number().nullable(),
  decisionZoneLayerKey: zod.string().nullable(),
  decisionZoneLayerRevision: zod.string().nullable(),
  yieldGoalLayerKey: zod.string().nullable(),
  yieldGoalLayerRevision: zod.string().nullable(),
  vrsLayerKey: zod.string().nullable(),
  vrsLayerRevision: zod.string().nullable(),
  status: zod.string().nullable(),
});

export const getVrsYieldGoalsResponse = zod.object({
  layerData: zod.array(vrsYieldGoal),
});

export const getMyyaListFieldops = zod.object({
  harvestType: zod.string(),
  cropYear: zod.number(),
  commodityId: zod.string().uuid(),
  commodityName: zod.string(),
  avgYield: zod.number().optional(),
  yieldUnit: zod.string(),
  coverageRatio: zod.number().optional(),
});

export const getMyyaListFieldopsResponse = zod.array(getMyyaListFieldops);
