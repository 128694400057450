import { useState, useCallback } from "react";
import { useAlerts } from "@granular/gds";
import { useFetchNewTokens } from "../services/auth/auth";
import { trpc as coreQueryClient } from "../services/trpc";
import { useLocalStorage } from "./localStorage";
import { useOperatingContext } from "./operatingContext";

function useMutationConfig() {
  const utils = coreQueryClient.useUtils();
  const fetchNewTokens = useFetchNewTokens();
  const { resetLocalStoredContexts } = useOperatingContext();

  const mutationConfig = {
    onSuccess: async () => {
      await fetchNewTokens();

      // We need to invalidate everything because the user's context has changed.
      await utils.invalidate();
      resetLocalStoredContexts();
      //TODO: Fix the local storage issue to allow react state to notify changes so this isn't needed.
      window.location.reload();
    },
  };

  return mutationConfig;
}

export function useStartImpersonation() {
  const mutationConfig = useMutationConfig();
  const {
    global: { danger },
  } = useAlerts();

  return coreQueryClient.auth.userImpersonationHandler.useMutation({
    ...mutationConfig,
    onError: (error) => {
      if (error?.data?.httpStatus === 404) {
        danger(error.message);
      }
    },
  });
}

export function useStopImpersonation() {
  const mutationConfig = useMutationConfig();
  return coreQueryClient.auth.deleteUserImpersonationHandler.useMutation(
    mutationConfig,
  );
}

export function usePreferTargetsFeatureFlags(): [
  boolean,
  (newValue: boolean) => void,
] {
  const [localStorageState, setLocalStorageState] = useLocalStorage(
    "preferTargetsFeatureFlags",
    "false",
    false,
  );
  const defaultState = localStorageState === "true";
  const [state, setState] = useState(defaultState);
  const setPreferTargetsFeatureFlags = useCallback(
    (newValue: boolean) => {
      setLocalStorageState(newValue.toString());
      setState(newValue);
    },
    [setLocalStorageState, setState],
  );

  return [state, setPreferTargetsFeatureFlags];
}
