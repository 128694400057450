import { getCurrentToken } from "@granular/fabric3-core";

type UploadFileProps = {
  file: File;
  chatId: string;
};

export const uploadFile = async ({ file, chatId }: UploadFileProps) => {
  const formData = new FormData();
  formData.append("file", file);
  const accessToken = getCurrentToken();
  const response = await fetch(`/api/chatbot-svc/chat/upload_file/${chatId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    body: formData,
    method: "POST",
  });
  return response;
};
