/**
 * TODO: This is a fucking mess. Talk to the BE teams (or just Adam or Rutt)
 *       and figure this shit out once and for all.
 */
//TODO: Remove these types once old SSO is retired.
export type SuccessfulAuthResponse = {
  accessToken: string;
  granularId: string;
  identityToken: string;
  logoutUrl: string;
  refreshToken: string;
  user: string;
};

type OpenID = {
  "cognito:username": string;
  aud: string;
  auth_time: number;
  email_verified: boolean;
  email: string | null;
  event_id: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  locale: string;
  phone_number_verified: boolean;
  sub: string;
  token_use: string;
};

// TODO: WTF?
type Custom = {
  "custom:tmp_marketing_optin"?: "1" | "0";
  "custom:granular_id"?: string;
  "custom:tmp_terms_ack_date"?: string;
  "custom:oem_origination"?: string;
  "custom:granular_super_admin"?: "1" | "0";
  "custom:encirca_id"?: string;
};

type JWT = OpenID & Custom; // TODO: WTF?
export type AuthenticationData = SuccessfulAuthResponse & JWT; // TODO: WTF?


// New SSO types. Backwards compatible with the previous implementation.
export type JwtPayload = {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  scp?: string;
  login_hint?: string;
  preferred_username?: string;
  email?: string;
  family_name: string;
  given_name: string;
  name: string;  
  granular_ad_groups?: string[];
  roles: string[];
  "custom:granular_id"?: string;
  "custom:granular_impersonation_id"?: string;
  "custom:granular_impersonation_email"?: string;
  "custom:granular_super_admin"?: "0" | "1";
};

export function parseJwt<T extends JwtPayload>(token: string): T {
  // Base64 decoding function compatible with Node.js and browsers
  function base64Decode(str: string): string {
    // For Node.js environment
    if (typeof window === 'undefined') {
      return Buffer.from(str, 'base64').toString('utf-8');
    }
    // For browser environment
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  const jsonPayload = base64Decode(base64 ?? "");
  return JSON.parse(jsonPayload) as T;
}
