import { useOperatingContext, useUserLocale } from "@granular/fabric3-core";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import {
  chatIdAtom,
  chatMessagesAtom,
  fetchingResponseAtom,
  showFeedbackBtnsAtom,
} from "../state/state";
import { fetchStream } from "../services/fetchStream";
import { processStreamResponse } from "../services/processStreamResponse";
import { ChatData } from "../Messages/ChatMessage";

type setChatMessages = (arg: (chatMessages: ChatData[]) => ChatData[]) => void;

const createUpdate = (setChatMessages: setChatMessages) => {
  return (chatMessage: ChatData) => {
    setChatMessages((chatMessages: ChatData[]) => {
      return chatMessages.at(-1)?.message.startsWith(chatMessage.message)
        ? [...chatMessages]
        : [...chatMessages, chatMessage];
    });
  };
};

export default function useStreamResponse() {
  const chatId = useAtomValue(chatIdAtom);
  const [fetchingResponse, setFetchingResponse] = useAtom(fetchingResponseAtom);
  const [messages, setChatMessages] = useAtom(chatMessagesAtom);
  const setShowFeedbackBtns = useSetAtom(showFeedbackBtnsAtom);
  const { contextSelected } = useOperatingContext();
  const locale = useUserLocale();
  const isFetchingRef = React.useRef(false);
  const updateChatMessages = createUpdate(setChatMessages);

  React.useEffect(() => {
    if (messages.length === 0 || !fetchingResponse || isFetchingRef.current) {
      return;
    }

    // Only request a new message from OpenAI if the last message was from the user.
    const lastMessage = messages.at(-1);
    if (!lastMessage?.userEntered || lastMessage?.isFile) {
      return;
    }

    const fetchData = async () => {
      isFetchingRef.current = true;
      try {
        const resp = await fetchStream({
          chatId,
          message: lastMessage.message,
          locale,
          contextId: contextSelected?.contextId,
        });

        if (!resp.ok) {
          isFetchingRef.current = false;
          console.error(`Request failed with status ${resp.status}`);
          return;
        }

        const reader = resp.body?.getReader();
        const decoder = new TextDecoder();
        if (!reader) {
          return;
        }
        processStreamResponse({
          reader,
          decoder,
          updateChatMessages,
        });
        setShowFeedbackBtns(true);
        setFetchingResponse(false);
        isFetchingRef.current = false;
      } catch (error) {
        setFetchingResponse(false);
        isFetchingRef.current = false;
        console.error(error);
        datadogRum.addError(error);
        const errorMsg = {
          message: "Server failed to respond...",
          userEntered: false,
          isFile: false,
          hidden: false,
        };
        updateChatMessages(errorMsg);
      }
    };

    void fetchData();
  }, [
    fetchingResponse,
    messages,
    chatId,
    contextSelected?.contextId,
    locale,
    updateChatMessages,
    setFetchingResponse,
    setShowFeedbackBtns,
  ]);
}
