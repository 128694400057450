import * as React from "react";
import { useLocation } from "react-router-dom";
import { useTimeout } from "@granular/fabric3-core";

const NotFound: React.FC = () => {
  const location = useLocation();
  const [waitedForLoading, setWaitedForLoading] = React.useState(false);
  useTimeout(() => setWaitedForLoading(true), 750);
  if (!waitedForLoading) {
    return (
      <div>
        <span role="img" aria-label="hourglass" className="pr-3">
          ⏳
        </span>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center p-5 h-100 bg-danger text-light">
      <div>
        <h1 className="display-1">{location.pathname}</h1>
        <p className="d-block">
          I&#8217;m sorry but I could not find anything at that route{" "}
          <span role="img" aria-label="sorry face">
            😕
          </span>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
