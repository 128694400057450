import {
  GKButton,
  GKCard,
  GKCardBody,
  GKCardHeader,
  GKCardTitle,
  GKIcon,
  GKIconName,
  GKInput,
  GKLoading,
  GKLoadingType,
} from "@granular/gds";
import {
  getCurrentToken,
  segment,
  useFeatureFlags,
  useOperatingContext,
  useUserLocale,
  useJwtUser,
} from "@granular/fabric3-core";
import React from "react";
import { Corteva_Pages } from "@granular/authz";
import { useIntl } from "react-intl";
import { ChatApi } from "@granular/chatbot-svc-client";
import { useAtom } from "jotai";
import { ChatHelpModal } from "./HelpModal";
import { InitChatMessage } from "./Messages/InitialPrompt";
import styles from "./Chat.module.css";
import { Upload } from "./Upload/Upload";
import { ChatMessage } from "./Messages/ChatMessage";
import {
  chatIdAtom,
  chatMessagesAtom,
  fetchingResponseAtom,
  showFeedbackBtnsAtom,
} from "./state/state";
import useStreamResponse from "./hooks/useStreamResponse";
import UploadProgress from "./Upload/UploadProgress";

export const Chat: React.FunctionComponent = () => {
  const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom);
  const [fetchingResponse, setFetchingResponse] = useAtom(fetchingResponseAtom);
  const [chatId, setChatId] = useAtom(chatIdAtom);
  const [showFeedbackBtns, setShowFeedbackBtns] = useAtom(showFeedbackBtnsAtom);
  const [showHelp, setShowHelp] = React.useState<boolean>(false);
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const [currentMessageString, setCurrentMessageString] =
    React.useState<string>("");
  const chatContainer = React.useRef<HTMLDivElement>(null);
  const latestQuestion = React.useRef<HTMLDivElement>(null);
  const latestAnswer = React.useRef<HTMLDivElement>(null);
  const bottomOfChatWindow = React.useRef<HTMLDivElement>(null);
  const textArea = React.useRef<HTMLDivElement>(null);
  const [questionPosition, setQuestionPosition] = React.useState<number>();
  const [autoScrolling, setAutoscrolling] = React.useState<boolean>(true);
  const [containerWidth, setContainerWidth] = React.useState<number>(500);
  const intl = useIntl();
  const locale = useUserLocale();
  const { userCan } = useOperatingContext();
  const flags = useFeatureFlags();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [disableChatForGrowers, setDisableChatForGrowers] =
    React.useState<boolean>(true);
  const tokenClaims = useJwtUser()[0];
  const tenantId = "3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4";

  let isCortevaUser = false;
  if (
    (tokenClaims?.iss ?? "").includes(tenantId) &&
    /^[^@]+@corteva\.com$/.test(tokenClaims?.email?.toLowerCase() ?? "")
  ) {
    isCortevaUser = true;
  }

  useStreamResponse();

  React.useEffect(() => {
    const fetchFlags = async () => {
      // Added a short delay to allow for fetching flags and preventing
      // a flash of the chat window if it's disabled
      await new Promise((resolve) => setTimeout(resolve, 60));
      setDisableChatForGrowers(
        (flags["app-chatbot-disable-for-growers"] as boolean) ?? true,
      );
      setLoading(false);
    };

    void fetchFlags();
  }, [flags]);

  React.useEffect(() => {
    // Scrolls the chat window to the latest question any time the
    // chat messages change. Once we've scrolled the question to
    // the top of the chat window, stop trying to scroll.
    const questionTop = Math.floor(questionPosition ?? 9999);
    const chatWindowTop = chatContainer.current?.getBoundingClientRect().y ?? 0;

    if (fetchingResponse && autoScrolling) {
      latestQuestion.current?.scrollIntoView({ behavior: "smooth" });
      setQuestionPosition(latestQuestion.current?.getBoundingClientRect().y);
    }

    if (autoScrolling && questionTop <= chatWindowTop) {
      setAutoscrolling(false);
    }
  }, [autoScrolling, chatMessages, fetchingResponse, questionPosition]);

  React.useEffect(() => {
    // When the chat container resizes, update the width of the
    // loading bar
    if (chatContainer.current) {
      setContainerWidth(chatContainer.current.offsetWidth);
    }

    // Scrolls the chat window to the bottom at the start of message
    // streaming and if the complete answer and question will fit in
    // the entire visible chat window.
    const chatWindowHeight = chatContainer.current?.offsetHeight ?? 0;
    const totalHeight = latestAnswer.current?.offsetHeight ?? 0;

    if (
      fetchingResponse ||
      (!fetchingResponse && totalHeight < chatWindowHeight)
    ) {
      bottomOfChatWindow.current?.scrollIntoView({ behavior: "smooth" });
      setQuestionPosition(undefined);
      setAutoscrolling(true);
    }

    if (!fetchingResponse) {
      // There's probably a better way to do this
      const questionEntry =
        textArea.current?.children.namedItem("questionEntry");

      if (questionEntry) {
        (questionEntry as HTMLElement).focus();
      }
    }
  }, [fetchingResponse]);

  React.useEffect(() => {
    if (showChat && !chatId) {
      const fetchChatId = async () => {
        const accessToken = getCurrentToken();
        const chatIdResp = await ChatApi.getInitChatChatInitGet({
          authToken: `Bearer ${accessToken}`,
        });
        setChatId(chatIdResp.chatId);
      };
      void fetchChatId();
    }
  }, [chatId, showChat, setChatId]);

  React.useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      const observer = new MutationObserver(() => {
        if (chatContainer.current && textArea.current) {
          // Add margin-right only in Safari to prevent scrollbar
          // from shifting content
          chatContainer.current.style.marginRight = "16px";
          // Add small gap between text area and chat container to enable
          // resize that is otherwise hidden by textarea in Safari
          textArea.current.style.bottom = "2px";
          observer.disconnect();
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => observer.disconnect();
    }
  }, []);

  React.useEffect(() => {
    // Use viewport height to set height of chat container to account for
    // Safari settings that put address bar at top of mobile screen
    const updateChatContainerHeight = () => {
      const chatContainer = document.querySelector(".chatContainer");
      if (chatContainer) {
        const viewportHeight = window.innerHeight;
        (chatContainer as HTMLDivElement).style.setProperty(
          "--vh",
          `${viewportHeight * 0.01}px`,
        );
      }
    };

    updateChatContainerHeight();
    window.addEventListener("resize", updateChatContainerHeight);

    return () => {
      window.removeEventListener("resize", updateChatContainerHeight);
    };
  }, []);

  const toggleChat = () => {
    if (!showChat) {
      segment.track("ChatbotOpen");
    }
    setShowChat(!showChat);
  };

  const askQuestion = (question: string) => {
    segment.track("ChatbotAskQuestion");
    setShowFeedbackBtns(false);
    setChatMessages((messages) => [
      ...messages,
      { message: question, userEntered: true, isFile: false, hidden: false },
    ]);
    setFetchingResponse(true);
  };

  const submitQuestion = () => {
    if (currentMessageString.trim() === "") {
      console.log("No question to submit!");
    } else {
      askQuestion(currentMessageString);
      setCurrentMessageString("");
    }
  };

  const assistantName = <span className={styles.aiName}>CARL</span>;

  const renderChatContent = () => (
    <>
      <ChatHelpModal open={showHelp} toggle={() => setShowHelp(false)} />
      <GKButton
        id="toggle-chat-1"
        color="primary"
        className={styles.chatButton}
        onClick={toggleChat}
      >
        <GKIcon
          name={
            showChat
              ? GKIconName.KeyboardArrowRight
              : GKIconName.KeyboardArrowLeft
          }
        ></GKIcon>
        <span className={styles.rotate}>{assistantName}</span>
      </GKButton>
      {showChat && (
        <GKCard className={styles.chatContainer}>
          <GKCardHeader style={{ backgroundColor: "#1460CC", color: "white" }}>
            <GKCardTitle className="d-flex my-0">
              <div className="mr-auto d-flex align-items-center">
                <span>
                  {intl.formatMessage({ id: "project" })} {assistantName}
                </span>
              </div>
              <div>
                <GKButton
                  color="primary"
                  onClick={() => setShowHelp(true)}
                  className="px-1"
                >
                  <GKIcon name={GKIconName.HelpOutline} />
                </GKButton>
                <GKButton color="primary" onClick={toggleChat} className="px-1">
                  <GKIcon name={GKIconName.Clear} />
                </GKButton>
              </div>
            </GKCardTitle>
          </GKCardHeader>
          <GKCardBody style={{ height: "498px", background: "#f7f7f7" }}>
            <div ref={chatContainer} className={styles.chatBody}>
              <InitChatMessage
                locale={locale}
                handleCannedPrompt={askQuestion}
              />
              {chatMessages.map((m, idx) => (
                <React.Fragment key={idx}>
                  {m.userEntered && (
                    <div key={`question-${idx}`} ref={latestQuestion}>
                      <ChatMessage
                        data={m}
                        showFeedbackBtns={
                          idx === chatMessages.length - 1 &&
                          !m.userEntered &&
                          showFeedbackBtns
                        }
                      />
                    </div>
                  )}
                  {!m.userEntered && (
                    <div key={`answer-${idx}`} ref={latestAnswer}>
                      <ChatMessage
                        data={m}
                        showFeedbackBtns={
                          idx === chatMessages.length - 1 &&
                          !m.userEntered &&
                          showFeedbackBtns
                        }
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
              <UploadProgress />
              <div ref={bottomOfChatWindow}></div>
            </div>
            {flags["app-chatbot-show-upload"] && <Upload />}
            <div ref={textArea} className={styles.prompt}>
              <GKInput
                name="questionEntry"
                type="textarea"
                disabled={fetchingResponse}
                placeholder={intl.formatMessage({ id: "ask_question_here" })}
                style={{
                  minHeight: "130px",
                  borderTop: "1px #E0E0E0 solid",
                  paddingLeft: flags["app-chatbot-show-upload"]
                    ? "30px"
                    : undefined,
                }}
                value={currentMessageString}
                onChange={(event) => {
                  setCurrentMessageString(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    submitQuestion();
                  }
                }}
              />
              <GKLoading
                isLoading={fetchingResponse}
                overlay={false}
                type={GKLoadingType.Bar}
                style={{ top: 0, right: 0, left: 0, position: "absolute" }}
                progressBarProps={{ height: 2, width: containerWidth }}
              />
            </div>
            <GKButton
              disabled={currentMessageString === ""}
              style={{
                position: "absolute",
                right: 0,
                width: "35px",
                bottom: "90px",
              }}
              color={"link"}
              onClick={submitQuestion}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                id="Layer_1"
                fill="white"
                stroke="#1460CC"
                strokeWidth="2px"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="15 21 10.64 13.36 3 9 21 3 15 21" />
                <line x1="11" y1="13" x2="16" y2="8" />
              </svg>
            </GKButton>
          </GKCardBody>
        </GKCard>
      )}
    </>
  );

  if (loading) {
    return;
  }

  if (disableChatForGrowers) {
    return isCortevaUser || userCan("view", Corteva_Pages.corteva_page)
      ? renderChatContent()
      : undefined;
  } else {
    return renderChatContent();
  }
};
