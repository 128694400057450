import "./Loading.css";

/**
 * Compute a path for a circle radius
 */
const getPath = (radius = 20): string => {
  return ["M" + radius + " 0c0-9.94-8.06", radius, radius, radius].join("-");
};

export default function Loading() {
  const color = "grey";
  const secondaryColor = "grey";
  const strokeWidth = 5;
  const strokeWidthSecondary = 3;
  const size = 40;
  const radius = 20;

  return (
    <div className="image-preview-loading">
      <svg
        width={size}
        height={size}
        stroke={color}
        aria-label="Loading"
        overflow="visible"
      >
        <g fill="none" fillRule="evenodd">
          <g
            transform={`translate(${size / 2} ${size / 2})`}
            strokeWidth={Number(strokeWidthSecondary || strokeWidth)}
          >
            <circle
              strokeOpacity=".5"
              cx="0"
              cy="0"
              r={radius}
              stroke={secondaryColor}
              strokeWidth={strokeWidth}
            />
            <path d={getPath(radius)}>
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  );
}
