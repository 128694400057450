import { GKButton, GKCard } from "@granular/gds";
import { useIntl } from "react-intl";
import styles from "./ChatMessage.module.css";

type InitChatMessageProp = {
  locale: string;
  handleCannedPrompt: (prompt: string) => void;
};

export const InitChatMessage: React.FunctionComponent<InitChatMessageProp> = ({
  locale,
  handleCannedPrompt,
}) => {
  const intl = useIntl();
  const msgs = [
    intl.formatMessage({ id: "welcome_im_your_ai_assist" }),
    intl.formatMessage({ id: "here_are_examples_of_things" }),
    intl.formatMessage({ id: "what_can_i_do_for_you_today" }),
  ];
  let cannedPrompts = [];
  locale.toUpperCase().includes("CA")
    ? (cannedPrompts = [
        // intl.formatMessage({ id: "tell_me_advantages_of_vorceed" }),
        intl.formatMessage({ id: "tell_me_about_pioneer_z_series" }),
        intl.formatMessage({ id: "show_me_pioneer_agronomy_resources" }),
      ])
    : (cannedPrompts = [
        // intl.formatMessage({ id: "tell_me_advantages_of_vorceed" }),
        intl.formatMessage({ id: "tell_me_about_pioneer_z_series" }),
        intl.formatMessage({ id: "tell_me_about_lumigen" }),
        intl.formatMessage({ id: "tell_me_about_nexta_biologicals" }),
      ]);
  // We would like to bring these back at some point but want them removed from the list for now
  // intl.formatMessage({ id: "summarize_corn_traits" }),
  // intl.formatMessage({ id: "granular_insights_features" }),

  return (
    <GKCard className={styles.chatMessage} style={{ marginRight: "25px" }}>
      <div
        className="my-0"
        style={{
          whiteSpace: "pre-wrap",
          fontFamily: "inherit",
        }}
      >
        {msgs[0]}
        <br />
        {msgs[1]}
        <ul className="pl-3 mb-0">
          {cannedPrompts.map((x, idx) => (
            <GKButton
              key={idx}
              color="link"
              onClick={() => handleCannedPrompt(x)}
              style={{
                lineHeight: "1.1rem",
                textAlign: "start",
                padding: "0",
                display: "block",
                width: "100%",
              }}
            >
              <li>{x}</li>
            </GKButton>
          ))}
        </ul>
        {msgs[2]}
      </div>
    </GKCard>
  );
};
