import { GKButton, GKIconName } from "@granular/gds";
import { useIntl } from "react-intl";
import { useAtomValue, useSetAtom } from "jotai";
import { chatIdAtom, chatMessagesAtom } from "./state/state";
import { deleteFile } from "./services/deleteFile";

type DeleteFileProps = {
  fileName: string;
};

export default function DeleteFile({ fileName }: DeleteFileProps) {
  const intl = useIntl();
  const chatId = useAtomValue(chatIdAtom);
  const setChatMessages = useSetAtom(chatMessagesAtom);

  const handleDelete = () => {
    // Ask the user to confirm deletion.
    const confirmDelete = window.confirm(
      intl.formatMessage({ id: "confirm_file_delete" }, { fileName }),
    );
    if (!confirmDelete) return;

    deleteFile({ chatId, fileName })
      .then((res) => {
        if (res.ok === true) {
          setChatMessages((messages) =>
            messages.filter((msg) => {
              return !msg.isFile || msg.message !== fileName;
            }),
          );
        } else {
          alert("Failed to delete file.");
        }
      })
      .catch((error) => {
        console.error("Failed to delete file:", error);
      });
  };

  return <GKButton onClick={handleDelete} icon={GKIconName.Delete} />;
}
