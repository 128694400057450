import { useState, useEffect } from "react";
import { parseJwt, JwtPayload } from "@granular/fabric3-definitions";
import { useToken } from "../services/auth/auth";

export function useJwtUser(): [
  JwtPayload | undefined,
  (claims: JwtPayload | undefined) => void,
] {
  const [token] = useToken();
  const [claims, setClaims] = useState<JwtPayload | undefined>(
    token ? parseJwt(token) : undefined,
  );

  useEffect(() => {
    const jwtClaims = token ? parseJwt(token) : undefined;
    setClaims(jwtClaims);
  }, [token]);

  return [claims, setClaims];
}
