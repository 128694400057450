import { getCurrentToken } from "@granular/fabric3-core";

type DownloadFileProps = {
  fileName: string;
  chatId: string;
};

export const downloadFile = async ({ fileName, chatId }: DownloadFileProps) => {
  const accessToken = getCurrentToken();
  try {
    const response = await fetch(
      `/api/chatbot-svc/chat/download_file?chatId=${chatId}&fileName=${fileName}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      },
    );
    if (response.ok === true) {
      const responseBlob = await response.blob();
      return { data: responseBlob, error: undefined };
    }
  } catch (error) {
    console.error("Failed to download file:", error);
  }
};
