import { Fabric3SidebarItem } from "@granular/fabric3-definitions";
import { GKIconName } from "@granular/gds";

/**
 * Exporting since this is used by the container _and_ the mobile Core Nav
 * manifest generation script (`pnpm monorepo:generate-core-nav-manifest`).
 * Keep it in one place.
 *
 * 👉 This is the source of truth for all the Sidebar (Core Nav) groupings. If
 * you need to add a new grouping, this is where you'd do it and
 * fah-gedda-baddit 🤷‍♂️👋
 *
 * NOTE: These are *untranslated keys*! We perform an I18n conversion in the
 * Container (the script doesn't need to do this).
 *
 * NOTE: These only specify _groupings_! You can always have a `null` value for
 * a grouping in `package.json` in which case, it will end up on the top of the
 * sidebar. Look for that implementation in the Container.
 */
export const CORE_NAV_GROUPINGS_AND_ORDER = [
  "PLAN",
  "ANALYZE",
  "MANAGE",
  "LABS",
] as const;

/**
 * This is shown when an app is disabled in the sidebar (Core Nav) and the user
 * hovers over it, *unless* the developers have specified a `disabledTooltip`
 * in their `package.json`
 *
 * TODO: Speak to product about what this should say.
 * TODO: Figure out how we're going to I18n this. This could just be a key 🤷‍♂️
 */
export const APP_DISABLED_MESSAGE = "Coming soon";

/**
 * Show this icon in case people do not specify it when (a) setting up their
 * app or (b) decide to monkey with it later.
 *
 * TODO: This is temporary until we modify `pnpm monorepo:check-package-json`
 * to check for correct values in each app's `package.json` using Zod.
 */
export const APP_ICON_IF_UNSPECIFIED = GKIconName.Fields;

/**
 * Give a name to the current tab so it we can switch between tabs, instead
 * of creating a new one everytime we click on the `Go to Insights/NRE` button
 * on either app. This name is unique and should not ever be reassigned.
 * Classic insights is aware of this name an calls its tab with it.
 * If you change it, it all falls apart, so please don't.
 */
export const CONTAINER_WINDOW_NAME = "new-rep-experience-browser-tab";

/**
 * We have apps and links that are fixed and always present towards the bottom
 * of the Sidebar/Core Navigation. These are them. In this variable, they're
 * defined exactly like their friends in the `fabric3.sidebar` key in each
 * app's `package.json` file (note the `Fabric3SidebarItem` typing).
 *
 * We transform them to Navigable Items (chiefly with actual icons and
 * translations) just like we do with the apps we discover dynamically when the
 * Container mounts.
 *
 * This is defined separately, exported, and transformed later since we need
 * this simple structure for one thing at the time of this writing: we need to
 * help our Mobile friends by generated a JSON manifest of all the stuff we
 * draw our Sidebar/Core Navigation with. There may be other uses later but
 * that's what we're doing now.
 */
export const APPS_LOWER_SIMPLE: Array<Partial<Fabric3SidebarItem>> = [
  {
    route: "/profile",
    icon: "AccountBox",
    label: "My_Profile",
    shortLabel: "Profile",
  },
  {
    /* This "route" is a hack for the playwright locators. The page objects look
     * for nav links using their hrefs. If a route has a href of '#',
     * playwright detects multiple matches on the locator and throws an error.
     */
    route: "#help",
    icon: "HelpOutline",
    label: "common_help",
    shortLabel: "common_help",
  },
  {
    /**
     * When people log out, make SSO remember the last view they were on and
     * redirect them to this view when they log back in.
     *
     * NOTE: This *may* be annoying with multiple accounts but if you don't
     * have "QA" in your job title, you may not need to deal with this.
     *
     * TODO: Do we just need `location.origin` and nothing more?
     *
     * TODO: Write a test for this.
     */
    href: "/logout",
    icon: "ArrowBack",
    label: "Log_Out",
    shortLabel: "Log_Out",
  },
];
