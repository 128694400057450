import { GKIcon, GKIconName, GKLoading, GKLoadingType } from "@granular/gds";
import { useAtom, useAtomValue } from "jotai";
import {
  fileNameAtom,
  uploadErrorMsgAtom,
  UploadStatus,
  uploadStatusAtom,
} from "../state/state";
import styles from "./UploadProgress.module.css";

/**
 * An in-progress or error'd upload.
 */
export default function UploadProgress() {
  const uploadStatus = useAtomValue(uploadStatusAtom);
  const uploadErrorMsg = useAtomValue(uploadErrorMsgAtom);
  const [fileName, setFileName] = useAtom(fileNameAtom);
  const error = uploadStatus === UploadStatus.ERROR;
  const headerText = error ? "Error uploading file" : "Uploading file";

  const dismissError = () => {
    setFileName("");
  };

  if (
    !fileName ||
    !uploadStatus ||
    ![UploadStatus.UPLOADING, UploadStatus.ERROR].includes(uploadStatus)
  ) {
    return;
  }

  return (
    <div className={styles.container}>
      <div className={`mh2 mt2 ${styles.loadingIconWrapper}`}>
        <div className={styles.loadingIcon}>
          <div className={styles.uploadingFileCircle}>
            <GKIcon name={GKIconName.File} />
          </div>
          <GKLoading
            inline={true}
            isLoading={!!uploadStatus}
            type={GKLoadingType.Spinner}
            spinnerProps={{ width: 40 }}
            {...(error && { value: 100 })}
          />
        </div>
      </div>
      <div
        className={
          error
            ? styles.uploadStatus + " " + styles.error
            : styles.uploadStatus + ` pv2 ph3 ml1-ns br3 ba bw05 `
        }
      >
        <div className={`${styles.message} ${styles.uploadText}`}>
          {headerText}: {fileName}
          <div style={{ opacity: error ? 1 : 0 }}>
            <button onClick={dismissError} className={styles.dismissError}>
              <GKIcon name={GKIconName.Clear} />
            </button>
          </div>
        </div>
        {error && uploadErrorMsg && (
          <div className={styles.uploadText}>{uploadErrorMsg}</div>
        )}
      </div>
    </div>
  );
}
