import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

/**
 * Represents if the app was loaded in an embedded web view on a mobile app.
 * Should only be set once by reading a signal from the URL search string.
 */
export const isEmbeddedState = atomWithStorage<boolean>(
  "isEmbedded",
  false,
  undefined,
  { getOnInit: true },
);
isEmbeddedState.debugLabel = "isEmbedded";

/**
 * Represents whether the navigation bar should be hidden, true if we are embedded in an app.
 */
export const disableNavState = atom((get) => get(isEmbeddedState) === true);
