/**
 * Assemble all wrappers/contexts/whatever here to provide... The Mama Shell 🥰
 * She takes care of all things and forgives mistakes (hopefully).
 *
 * NOTE: This MUST be **Headless**! No DOM/UI except for that small Loading
 * component!
 */

import type { FC, ReactNode } from "react";
import queryString from "query-string";
import { ThirdParty } from "../components/ThirdParty";

import ErrorBoundary from "./ErrorBoundary";
import FeatureFlagWrapper from "./FeatureFlags";
import HelmetWrapper from "./Helmet";
import LoginWrapper from "./Login";
import RPCWrapper from "./TrpcWrapper";
import { useSetAtom } from "jotai/react";
import { isEmbeddedState } from "../state/mobile";

const Shell: FC<{ children: ReactNode }> = ({ children }) => {
  const setIsEmbedded = useSetAtom(isEmbeddedState);
  const isEmbedded = queryString.parse(window.location.search).isEmbedded;
  if (isEmbedded !== null && isEmbedded !== undefined) {
    if (isEmbedded.toLocaleString().toLocaleLowerCase() !== "false") {
      setIsEmbedded(true);
    } else {
      setIsEmbedded(false);
    }
  }

  return (
    /**
     * NOTE: ORDER IS IMPORTANT HERE!
     *
     * You or the customer will need to be authenticated to do pretty much
     * everything. This is handled by the Login layer of this onion 🧅
     *
     * You can then use the tRPC layer to fire all manner of requests to the
     * backend. That's really it.
     *
     * There certainly _have_ been edge cases in the past where we've had to
     * rethink the order of things in this onion but this is OK for now. If
     * your PM wants you to do anything out of order, contact us and we'll work
     * something out 🤘
     *
     * NOTE: No wrapping inside the `React.StrictMode` component! This is done
     * by `main.tsx`.
     *
     * NOTE: You'll notice that we're not supplying an I18n provider here. We
     * do that in the Container. This is because we want each app to ship with
     * its own translations (like `en_US.json` from Lokalise) and consider the
     * Container to be an 'App'. It's just a pattern thing, really. When I go
     * into any app, I expect it to
     *
     * 1) Have it's translations in `src/i18n`
     *
     * 2) Mount with its own I18n provider (i.e., the default export in
     *    `src/App.tsx`)
     *
     * The Container, the 'Ultimate App', should be no different 🌸
     *
     */
    <LoginWrapper>
      <RPCWrapper>
        <ErrorBoundary>
          <ThirdParty>
            <FeatureFlagWrapper>
              <HelmetWrapper>{children}</HelmetWrapper>
            </FeatureFlagWrapper>
          </ThirdParty>
        </ErrorBoundary>
      </RPCWrapper>
    </LoginWrapper>
  );
};

export default Shell;
