import { useMemo, useState, useCallback, useEffect } from "react";
import { useLocalStorage } from "../../hooks/localStorage";
import * as cognito from "./cognito";
import * as msal from "./msal";

export const getCurrentToken = () => {
  const token =
    localStorage.getItem("newSSO") === "true"
      ? localStorage.getItem(msal.LOCAL_STORAGE_ACCESS_KEY) ?? ""
      : localStorage.getItem(cognito.LOCAL_STORAGE_IDENTITY_KEY) ?? "";

  console.log("getting token from cache", token);
  return token;
};

const useSSOImplementation = (): [boolean, (newValue: boolean) => void] => {
  const [localStorageState, setLocalStorageState] = useLocalStorage(
    "newSSO",
    "false",
    false,
  );
  const defaultState = localStorageState === "true";
  const [state, setState] = useState(defaultState);
  const setNewSso = useCallback(
    (newValue: boolean) => {
      setLocalStorageState(newValue.toString());
      setState(newValue);
    },
    [setLocalStorageState, setState],
  );

  return [state, setNewSso];
};

export const useToken = () => {
  const sso = useSSO();
  return sso.useToken();
};

export const useInitializeAuth = () => {
  const [, setToken] = useToken();
  const sso = useSSO();
  return useCallback(() => sso.initialize(setToken), [sso, setToken]);
};

export const useLogout = () => {
  const sso = useSSO();
  return useCallback(() => sso.logout(), [sso]);
};

export const useFetchNewTokens = () => {
  const [, setToken] = useToken();
  const sso = useSSO();

  return useCallback(async () => {
    if (sso.fetchNewTokens) {
      await sso.fetchNewTokens((token) => {
        console.log("fetchNewTokens", token);
        setToken(token);
      });
    }
  }, [sso, setToken]);
};

const useSSO = () => {
  const [sso, setSSO] = useSSOImplementation();
  // We don't have access to feature flags yet - they require a user.
  // Allow the user to enable the new SSO flow through a query parameter.
  const searchParams = new URLSearchParams(window.location.search);
  const enableNewSSO = searchParams.get("newSSO");

  // We can't rely on the query parameter to persist as we navigate around the app.
  // This will effectively allow the user to opt in.
  useEffect(() => {
    if (enableNewSSO) {
      setSSO(true);
    }
  });

  const impl = () =>
    sso === true
      ? {
          initialize: msal.initialize,
          useToken: msal.useToken,
          logout: msal.logout,
          fetchNewTokens: msal.fetchNewTokens,
        }
      : {
          initialize: cognito.initialize,
          useToken: cognito.useToken,
          logout: cognito.logout,
        };

  return useMemo(impl, [sso]);
};
