import { GKButton, GKIconName } from "@granular/gds";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useRef } from "react";
import { useIntl } from "react-intl";
import {
  chatIdAtom,
  chatMessagesAtom,
  fetchingResponseAtom,
  fileNameAtom,
  uploadErrorMsgAtom,
  UploadStatus,
  uploadStatusAtom,
} from "../state/state";
import { uploadFile } from "../services/uploadFile";

type PostFileResponse = {
  message: string;
  file_name: string;
};

type PostFileError = {
  detail: string;
};

export const Upload: React.FunctionComponent = () => {
  const fileInput = useRef<HTMLInputElement>(null);
  const chatId = useAtomValue(chatIdAtom);
  const [fetchingResponse, setFetchingResponse] = useAtom(fetchingResponseAtom);
  const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom);
  const setFileName = useSetAtom(fileNameAtom);
  const setUploadStatus = useSetAtom(uploadStatusAtom);
  const setUploadErrorMsg = useSetAtom(uploadErrorMsgAtom);
  const intl = useIntl();
  const postImageSummaryPrompt = intl.formatMessage({
    id: "chatbot_describe_file_prompt",
  });

  const Tooltip = () => {
    return (
      <>
        <div>
          {intl.formatMessage({ id: "chatbot_image_upload_instructions" })}
        </div>
        <div>{intl.formatMessage({ id: "chatbot_image_types_allowed" })}</div>
      </>
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0]!;
      void handleFileUpload(selectedFile);
    }
  };

  const handleFileUpload = async (file: File) => {
    setUploadStatus(UploadStatus.UPLOADING);
    setFetchingResponse(true);
    try {
      setFileName(file.name);
      const response = await uploadFile({ file, chatId });
      if (response.ok) {
        const resp = (await response.json()) as PostFileResponse;
        const fileName = resp.file_name ?? file.name;
        setChatMessages((chatMessages) => [
          ...chatMessages,
          {
            isFile: true,
            message: fileName,
            userEntered: true,
            hidden: false,
          },
          {
            isFile: false,
            message: postImageSummaryPrompt,
            userEntered: true,
            hidden: true,
          },
        ]);
      } else {
        const resp = (await response.json()) as PostFileError;
        setUploadErrorMsg(resp.detail);
        setUploadStatus(UploadStatus.ERROR);
        setFetchingResponse(false);
      }
    } catch (error) {
      setUploadStatus(UploadStatus.ERROR);
      setFetchingResponse(false);
      console.error("Failed to upload file:", error);
    } finally {
      if (fileInput.current) {
        fileInput.current.value = "";
      }
    }
  };

  return (
    <>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileChange}
        hidden={true}
      />
      <GKButton
        className="mr-4 mt-4"
        style={{
          position: "absolute",
          left: 0,
          width: "35px",
          bottom: "95px",
          zIndex: "1000",
        }}
        disabled={fetchingResponse || chatMessages.length === 0}
        color="link"
        icon={GKIconName.AttachFile}
        tooltip={<Tooltip />}
        tooltipPlacement="top"
        onClick={() => fileInput.current?.click()}
      />
    </>
  );
};
