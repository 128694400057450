import {
  PersistedClient,
  Persister,
} from "@tanstack/react-query-persist-client";
import { get, set, del } from "idb-keyval";

/**
 * Query persister to store the cache to IndexDB using idb-keyval
 * https://github.com/TanStack/query/blob/main/packages/query-async-storage-persister/src/index.ts
 */

export type AsyncThrottleOptions = {
  interval?: number;
  onError?: (error: unknown) => void;
};
export type MaybePromise<T> = T | Promise<T>;
export type Promisable<T> = T | PromiseLike<T>;
export type AsyncPersistRetryer = (props: {
  persistedClient: PersistedClient;
  error: Error;
  errorCount: number;
}) => Promisable<PersistedClient | undefined>;
export type CreateStoragePersisterOptions = {
  /** The storage client used for setting and retrieving items from cache.
   * For SSR pass in `undefined`. Note that window.localStorage can be
   * `null` in Android WebViews depending on how they are configured.
   */
  // storage: Storage | undefined | null
  /** The key to use when storing the cache */
  key?: string;
  /** To avoid spamming,
   * pass a time in ms to throttle saving the cache to disk */
  throttleTime?: number;
  /**
   * How to serialize the data to storage.
   * @default `JSON.stringify`
   */
  serialize?: (client: PersistedClient) => MaybePromise<string>;
  /**
   * How to deserialize the data from storage.
   * @default `JSON.parse`
   */
  deserialize?: (cachedString: string) => MaybePromise<PersistedClient>;

  retry?: AsyncPersistRetryer;
};

export function asyncThrottle<TArgs extends readonly unknown[]>(
  func: (...args: TArgs) => Promise<void>,
  { interval = 1000, onError }: AsyncThrottleOptions = {},
) {
  if (typeof func !== "function") throw new Error("argument is not function.");

  let nextExecutionTime = 0;
  let lastArgs;
  let isExecuting = false;
  let isScheduled = false;

  return async (...args: TArgs) => {
    lastArgs = args;
    if (isScheduled) return;
    isScheduled = true;
    while (isExecuting) {
      await new Promise((done) => setTimeout(done, interval));
    }
    while (Date.now() < nextExecutionTime) {
      // eslint-disable-next-line no-loop-func
      await new Promise((done) =>
        setTimeout(done, nextExecutionTime - Date.now()),
      );
    }
    isScheduled = false;
    isExecuting = true;
    try {
      await func(...lastArgs);
    } catch (error) {
      try {
        onError?.(error);
      } catch {
        console.warn("[QuerySyncIdbPersister]:", error);
      }
    }
    nextExecutionTime = Date.now() + interval;
    isExecuting = false;
  };
}

export const createIndexDBStoragePersister = ({
  // storage,
  key = `REACT_QUERY_OFFLINE_CACHE`,
  throttleTime = 1000,
  serialize = JSON.stringify,
  deserialize = JSON.parse,
  retry,
}: CreateStoragePersisterOptions): Persister => {
  const trySave = async (
    persistedClient: PersistedClient,
  ): Promise<Error | undefined> => {
    try {
      await set(key, await serialize(persistedClient));
      return;
    } catch (error) {
      return error as Error;
    }
  };
  return {
    persistClient: asyncThrottle(
      async (persistedClient) => {
        let client: PersistedClient | undefined = persistedClient;
        let error = await trySave(client);
        let errorCount = 0;
        while (error && client) {
          errorCount++;
          client = await retry?.({
            persistedClient: client,
            error,
            errorCount,
          });

          if (client) {
            error = await trySave(client);
          }
        }
      },
      { interval: throttleTime },
    ),
    restoreClient: async () => {
      const cacheString: string | undefined = await get(key);

      if (!cacheString) {
        return;
      }

      return await deserialize(cacheString);
    },
    removeClient: async () => {
      await del(key);
    },
  };
};
