import { useEffect } from "react";

export function useTimeout<A extends unknown[]>(
  callback?: ((...args: A) => void | Promise<void>) | null,
  ms?: number | null,
  ...args: A
): void {
  useEffect(
    () => {
      if (!callback || ms === null) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      const id = setTimeout(callback, ms, ...args);
      return () => clearInterval(id);
    },
    // Eslint says we need to add `args` to the dependency array, but in reality,
    // spreading it is the right thing to do.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ms, ...args],
  );
}
