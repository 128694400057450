import { ChatData } from "../Messages/ChatMessage";

type ProcessStreamResponseProps = {
  reader: ReadableStreamDefaultReader<Uint8Array>;
  decoder: TextDecoder;
  updateChatMessages: (chatMessage: ChatData) => void;
};

export const processStreamResponse = ({
  reader,
  decoder,
  updateChatMessages,
}: ProcessStreamResponseProps) => {
  const respChatMessage: ChatData = {
    message: "",
    userEntered: false,
    isFile: false,
    hidden: false,
  };

  const readChunk = async () => {
    const { done, value } = await reader.read();
    if (done) {
      return;
    }

    const chunk = decoder.decode(value, { stream: true });
    respChatMessage.message += chunk;
    updateChatMessages(respChatMessage);
    void readChunk(); // Continue reading the next chunk
  };

  void readChunk();
};
