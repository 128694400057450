// These schemas will eventually come from a TS client generated from vrs-service, but
// that doesn't exist yet.
import { z as zod } from "zod";

export const idealLine = zod.object({
  maxSeedingRate: zod.number(),
  minSeedingRate: zod.number(),
  minExtrapolatedSeedingRate: zod.number().optional(),
  maxYieldTarget: zod.number(),
  minYieldTarget: zod.number(),
  minExtrapolatedYieldTarget: zod.number().optional(),
  method: zod.string(),
});
export const getIdealLineResponse = zod.object({
  data: idealLine,
});

export const baseMachineType = zod.object({
  machineName: zod.string(),
  machineDisplayName: zod.string(),
});
export const machineType = baseMachineType.extend({
  supportedFeatures: zod.array(zod.string()).nullable(),
});

export const getV3ExportsConfigurationsResponse = zod.array(machineType);

export const recommendationStatus = zod.object({
  activity_id: zod.string().uuid(),
  status: zod.string(),
  modified_on: zod.string(),
});

export const getV4VrsRecommendationStatusesResponse =
  zod.array(recommendationStatus);

export const seedingCurveDefaultsCorn = zod.object({
  standLoss: zod.number(),
  seedCost: zod.number(),
  grainPrice: zod.number(),
  minExtrapolatedSeedingRate: zod.number().optional(),
  minSeedingRate: zod.number(),
  maxSeedingRate: zod.number(),
  minExtrapolatedYieldTarget: zod.number().optional(),
  minYieldTarget: zod.number(),
  maxYieldTarget: zod.number(),
  rateIncrement: zod.number(),
  seedingCurveType: zod.string(),
});

const preferences = zod.object({
  seedId: zod.string(),
  cropSeasonId: zod.string().optional(),
});

export const seedingCurvePreferencesCorn =
  seedingCurveDefaultsCorn.merge(preferences);

export const operationSeedingCurvePreferencesCorn = zod.object({
  defaults: seedingCurveDefaultsCorn,
  preferences: zod.array(seedingCurvePreferencesCorn),
});

export const getV4OperationSeedingCurvePreferencesCornResponse =
  operationSeedingCurvePreferencesCorn;

export const seedingCurveDefaultsSoybeans = zod.object({
  minSeedingRate: zod.number(),
  maxSeedingRate: zod.number(),
  localSeedingRate: zod.number().nullable(),
  rateIncrement: zod.number(),
});

export const seedingCurvePreferencesSoybeans =
  seedingCurveDefaultsSoybeans.merge(preferences);

export const operationSeedingCurvePreferencesSoybeans = zod.object({
  defaults: seedingCurveDefaultsSoybeans,
  preferences: zod.array(seedingCurvePreferencesSoybeans),
});

export const getV4OperationSeedingCurvePreferencesSoybeansResponse =
  operationSeedingCurvePreferencesSoybeans;

export const deleteOperationSeedingCurvePreferencesResponse = zod.string();

const metaPrescriptionRecord = zod.object({
  recordId: zod.string().uuid(),
  productId: zod.string().uuid(),
});
const deleteMetaItem = zod.object({
  activityId: zod.string().uuid(),
  layerReferenceId: zod.string().uuid(),
  prescriptionRecords: zod.array(metaPrescriptionRecord),
});
export const getDeleteSeedingCurvePreferencesStatusResponse = zod.object({
  jobId: zod.string().uuid().optional(),
  hashKey: zod.string().optional(),
  transactionId: zod.string().uuid().optional(),
  seedIds: zod.array(zod.string().uuid()).optional(),
  cropType: zod.string().optional(),
  cropYear: zod.number().optional(),
  status: zod.string(),
  step: zod.string().optional(),
  statusCode: zod.number().nullable(),
  meta: zod.array(deleteMetaItem).optional(),
  errorMessage: zod.string().optional(),
});

export const statsItem = zod.object({
  activityId: zod.string().uuid(),
  recordId: zod.string().uuid(),
  minCalcSeedingRate: zod.number(),
  maxCalcSeedingRate: zod.number(),
  avgCalcSeedingRate: zod.number(),
  minSeedingRate: zod.number(),
  maxSeedingRate: zod.number(),
  avgSeedingRate: zod.number(),
  calcUnits: zod.number(), // This is number of bags.
  units: zod.number(), // This is number of bags.
});

export const getV4PrescriptionStatsResponse = zod.object({
  stats: zod.array(statsItem),
});

export const recordPrescriptionPreferencesCorn = zod.object({
  activityId: zod.string().uuid().nullable(),
  recordId: zod.string().uuid().nullable(),
  zoneSetId: zod.string().nullable(),
  zoneSetRevisionId: zod.string().nullable(),
  seedId: zod.string().nullable(),
  rateIncrement: zod.number().nullable(),
  seedCost: zod.number().nullable(),
  grainPrice: zod.number().nullable(),
  standLoss: zod.number().nullable(),
  minYieldTarget: zod.number().nullable(),
  maxYieldTarget: zod.number().nullable(),
  minSeedingRate: zod.number().nullable(),
  maxSeedingRate: zod.number().nullable(),
  drylandYieldGoal: zod.number().nullable(),
  irrYieldGoal: zod.number().nullable(),
  yieldGoalUnitOfMeasure: zod.string().nullable(),
  seedingCurveType: zod.string(),
});

export const recordPrescriptionPreferencesSoybeans = zod.object({
  activityId: zod.string().nullable(),
  recordId: zod.string().nullable(),
  zoneSetId: zod.string().nullable(),
  zoneSetRevisionId: zod.string().nullable(),
  seedId: zod.string().nullable(),
  rateIncrement: zod.number().nullable(),
  localSeedingRate: zod.number().nullable(),
  minSeedingRate: zod.number().nullable(),
  maxSeedingRate: zod.number().nullable(),
  drylandYieldGoal: zod.number().nullable(),
  irrYieldGoal: zod.number().nullable(),
  yieldGoalUnitOfMeasure: zod.string().nullable(),
});

export const getV4PrescriptionSeedingPreferencesResponse = zod.object({
  cornPrescriptionPreferences: zod.array(recordPrescriptionPreferencesCorn),
  soybeansPrescriptionPreferences: zod.array(
    recordPrescriptionPreferencesSoybeans,
  ),
});

export const irrigatedSettings = zod.object({
  seedingCurveType: zod.string().optional(),
  minSeedingRate: zod.number(),
  maxSeedingRate: zod.number(),
  rateIncrement: zod.number(),
  minYieldTarget: zod.number().optional(),
  maxYieldTarget: zod.number().optional(),
  seedCost: zod.number().optional(),
  standLoss: zod.number().optional(),
  grainPrice: zod.number().optional(),
});
export const drylandSettings = irrigatedSettings.merge(
  zod.object({ localSeedingRate: zod.number().optional(),
}));

export const seedingOptimizer = zod.object({
  type: zod.string(),
  zoneSet: zod.string().uuid(),
  preferredUnitSystem: zod.string(),
});

export const varietyInput = zod.object({
  seedId: zod.string().uuid(),
  cropId: zod.string().uuid(),
  drylandSettings: drylandSettings,
  irrigatedSettings: irrigatedSettings.optional(),
  isBackup: zod.boolean(),
});

export const prescriptionInput = zod.object({
  activityId: zod.string().uuid(),
  description: zod.string(),
  seedId: zod.string().uuid(),
  treatmentId: zod.string().uuid().nullish(),
  seedingOptimizer: seedingOptimizer,
  fieldId: zod.string().uuid(),
  fieldRevisionId: zod.string().uuid(),
  cropId: zod.string().uuid(),
  eventDate: zod.string().nullable(),
  cropSeasonId: zod.string().uuid(),
});

export const runVrsRequest = zod.object({
  varietyInputs: zod.array(varietyInput),
  prescriptionInputs: zod.array(prescriptionInput),
  isFlatRate: zod.boolean().optional(),
});

export const rerunVrsRequest = zod.object({
  seedId: zod.string(),
  cropSeasonId: zod.string(),
  preferredUnitSystem: zod.string(),
});

export const runVrsResponse = zod.string(); // The command ID
export const rerunVrsResponse = zod.string(); // The command ID

export const runAutoVrsResponse = zod.object({
  successful: zod.array(zod.string()),
  failed: zod.array(zod.string()),
});

export const vrsPollResult = zod.object({
  createdBy: zod.string(),
  commandId: zod.string(),
  parentCommandId: zod.string().nullable(),
  optionalCorrelationId: zod.string().nullable(),
  status: zod.string(),
});

export const vrsGetStatusesForCommandResponse = zod.array(vrsPollResult);

const sendTos = zod.object({
  download: zod.boolean(),
  emailAddresses: zod.string().array(),
  emailSubject: zod.string(),
  emailMessage: zod.string(),
  deereMachineIds: zod.string().array(),
  deereOpsCenter: zod.boolean(),
  deereWorkPlan: zod.boolean(),
});
export const airSeederConversionRecord = zod.object({
  recordId: zod.string(),
  productId: zod.string(),
  conversionFactor: zod.number(),
});
export const vrsExportData = zod.object({
  records: zod.string().array(),
  sendTos,
  configurations: baseMachineType,
  prescriptionRateType: zod.string(),
  mergeExportedFilesPerField: zod.boolean(),
  airSeederConversion: zod.array(airSeederConversionRecord).optional(),
  preferredUnitSystem: zod.string().optional(),
  includeReport: zod.boolean(),
  includePrimaryVarietiesOnly: zod.boolean(),
  exportType: zod.string(),
});
export const exportVrsResponse = zod.object({
  exportIds: zod.string().array(),
});
