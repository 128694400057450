/**
 * This is a pretty important component (after Login). How you set this up will
 * determine how the client speaks to the Fabric3 RPC server.
 *
 * That is to say, this the only place where the tRPC Client library is
 * configured for all Fabric3 Apps. So PLEASE BE CAREFUL! Read the docs, know
 * what you're doing, and don't just do things because you saw some 'cool shit'
 * in a Medium post or a HackerNews comment.
 */

import { RPC_SERVER_ENDPOINTS } from "@granular/fabric3-definitions";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import {
  PersistQueryClientProvider,
  removeOldestQuery,
} from "@tanstack/react-query-persist-client";
import {
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
} from "@trpc/react-query";
import React, { useMemo } from "react";
import superjson from "superjson";
import { useToken } from "../services/auth/auth";
import { trpc } from "../services/trpc";
import { createIndexDBStoragePersister } from "../helpers/QueryIdbPersister";

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * Fail immediately! TODO: Change this later based on all your
       * learnings about React Query.
       */
      retry: false,
      /**
       * The duration until inactive queries will be removed from the cache by garbage collector.
       * It should be set as the same value or higher than persistQueryClient's maxAge option.
       * E.g. if maxAge is 24 hours (the default) then cacheTime should be 24 hours or higher.
       * If lower than maxAge, garbage collection will kick in and discard the stored cache earlier than expected.
       */
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      /**
       * The duration until a query transitions from fresh to stale.
       * As long as the query is fresh, data will always be read from the cache only - no network request will happen!
       * This default value can be overridden on a per-query basis when necessary.
       */
      // keep the line below commented until we write docs and an eslint rule to make sure people add staleTime to their queries
      // staleTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
    },
  },
  queryCache,
});

const TRPCWrapper = ({ children }: { children: React.ReactNode }) => {
  // TODO: State transitions? Validity? Address race condition!
  const [accessToken] = useToken();

  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;
  const endpoint =
    import.meta.env.MODE === "production"
      ? RPC_SERVER_ENDPOINTS.remote
      : RPC_SERVER_ENDPOINTS.local;
  /**
   * This URL length limit should be just less than AWS Cloudfront Limit. This
   * warns the user even if they chose `skipBatch: true`, and the setting keeps
   * batches below this total size, so large batches will be split. A single
   * "batched" request with a URL larger than this might still result in a HTTP
   * 413 error.
   * https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/cloudfront-limits.html#limits-general
   */
  const maxUrlLength = 8100;
  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        /**
         * This setup/array is very cool and is Node as Fuck ✨♥️ I LOVE IT. You
         * create all these async streams that can log, retrieve, relay
         * telemetry, persist to browser storage, all right here 🚀
         *
         * NOTE: 👉👉👉 If the fact that this is an array has not clicked, ORDER
         * IS IMPORTANT HERE!
         */
        links: [
          /**
           * We may not need this level of logging in the browser console but I
           * know that Nick Galloway would've loved this 🥲
           */
          loggerLink({
            enabled: (opts) =>
              (import.meta.env.MODE !== "production" &&
                typeof window !== "undefined") ||
              /**
               * From the docs: "The function passed to enabled is an example in
               * case you want to the link to log to your console in development
               * and only log errors in production."
               */
              (opts.direction === "down" && opts.result instanceof Error),
          }),

          /**
           * We use a `splitLink` because we want clients to be able to 'break
           * out' of batch-mode as and when they please. For example, this would
           * be used for known, slow requests so that their fetching does not
           * block UI rendering.
           *
           * To use this on the client, you would pass a context object that
           * sets `skipBatch` to `true`.
           */
          splitLink({
            condition: (op) => {
              try {
                const inputPayload = encodeURIComponent(JSON.stringify(op));
                if (inputPayload.length >= maxUrlLength) {
                  throw new Error(
                    `This tRPC input for ${op.path} is ${inputPayload.length} bytes, which is larger than limit of: ${maxUrlLength}.`,
                  );
                }
              } catch (error) {
                console.warn(
                  `${String(error)} This will likely result in a HTTP 413 error.`,
                );
              }
              return op.context.skipBatch === true;
            },
            true: httpLink({
              url: endpoint,
              /**
               * NOTE: This will be called with every request because it's a
               * function. Same with the `httpBatchLink` below.
               */
              headers: () => ({
                Authorization: authHeader,
              }),
            }),
            false: httpBatchLink({
              url: endpoint,
              maxURLLength: maxUrlLength,
              headers: () => ({
                Authorization: authHeader,
              }),
            }),
          }),
        ],

        /**
         * Use superjson so we can send Maps, Sets, and (importantly) Datetime
         * objects over the wire instead of serde-ing them in JSON ✨ Small
         * optimization, really, but this is rather nice for developer
         * experience.
         *
         * NOTE: If you ever remove this, remove it in the server as well!
         */
        transformer: superjson,
      }),
    [authHeader, endpoint],
  );

  /**
   * This is the configuration for how we persist the query cache between
   * sessions and visits, this way we can display data to the user even if
   * they unload and reload the app. Using this properly requires settings
   * `staleTime` on all of your queries. Please discuss with Tim Bendt if
   * you really believe we don't need this.
   */
  const localStoragePersister = createIndexDBStoragePersister({
    retry: removeOldestQuery,
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: localStoragePersister,
        buster:
          window.localStorage.getItem("corteva-access-token") ?? undefined,
      }}
      onSuccess={() => {
        console.debug("Success restoring from cache");
      }}
    >
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        {children}
      </trpc.Provider>
    </PersistQueryClientProvider>
  );
};

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <TRPCWrapper>{children}</TRPCWrapper>
);

export default Wrapper;
