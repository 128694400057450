import { getCurrentToken } from "@granular/fabric3-core";

type FetchStreamProps = {
  chatId: string;
  message: string;
  locale: string;
  contextId: string | undefined;
};

export const fetchStream = async ({
  chatId,
  message,
  locale,
  contextId,
}: FetchStreamProps) => {
  const accessToken = getCurrentToken();
  const chatBody = {
    chatId,
    message,
    locale,
  };
  const resp = await fetch("/api/chatbot-svc/chat/streaming", {
    headers: {
      authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-context": contextId ?? "",
    },
    body: JSON.stringify(chatBody),
    method: "POST",
  });
  return resp;
};
