import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { downloadFile } from "../services/downloadFile";
import { chatIdAtom, UploadStatus, uploadStatusAtom } from "../state/state";
import styles from "./ImagePreview.module.css";
import Error from "./Error";
import Loading from "./Loading";

type ImagePreviewProps = {
  fileName: string;
};

export default function ImagePreview({ fileName }: ImagePreviewProps) {
  const [data, setData] = React.useState<Blob | undefined>();
  const [isLoading, setIsLoading] = React.useState(true);
  const chatId = useAtomValue(chatIdAtom);
  const setUploadStatus = useSetAtom(uploadStatusAtom);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await downloadFile({ fileName, chatId });
      if (result) {
        setUploadStatus(UploadStatus.NONE);
        setData(result.data);
      }
    };
    void fetchData();
  }, [fileName, chatId, setUploadStatus]);

  const url = React.useMemo(() => data && URL.createObjectURL(data), [data]);

  if (!data) {
    return <Error />;
  }

  return (
    <>
      {isLoading && <Loading />}
      {url && (
        <div className={styles.imageContainer}>
          <img
            className={styles.imagePreview}
            alt={fileName}
            src={url}
            onLoad={() => setIsLoading(false)}
          />
        </div>
      )}
    </>
  );
}
