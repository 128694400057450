import { atom } from "jotai";
import { ChatData } from "../Messages/ChatMessage";

export enum UploadStatus {
  UPLOADING = "uploading",
  ERROR = "error",
  NONE = "",
}

export const chatIdAtom = atom<string>("");

export const fetchingResponseAtom = atom<boolean>(false);

export const uploadStatusAtom = atom<UploadStatus>(UploadStatus.NONE);

export const fileNameAtom = atom<string>("");

export const chatMessagesAtom = atom<ChatData[]>([]);

export const uploadErrorMsgAtom = atom<string>("");

export const showFeedbackBtnsAtom = atom<boolean>(false);
