/**
 * KEEP THIS FLAT. Keep this as flat as possible! And don't dump every possible
 * constant here. Stuff here should affect EVERY SINGLE THING in this Monorepo!
 *
 * If you are going to export a map/object or array, mark it `as const` so that
 * the type-checker throws a well-deserved shit-fit 😡
 *
 * In a lot of cases, the definitions here drive types and Zod schemas. But not
 * ALL cases; apply wisdom to your enthusiasm.
 *
 * When this gets to be about 500-1,000 lines long WITH comments, split it up.
 * Until then, resist the urge and keep it a single file. You can do this 🌸
 */

/** ═════════════════════════ SSO/Authentication ═════════════════════════  */

// SSO LocalStorage Stuff
export const LOCAL_STORAGE_USER_CREDS_REFRESH_IN_MS = 3_000_000; // This is approx 50 minutes. Why "approx"? Because JavaScript 🥰🤣
export const LOCAL_STORAGE_BOOTSTRAP_MUTEX = "corteva-auth-mutex";
export const LOCAL_STORAGE_REFRESH_KEY = "corteva-refresh-token";

/** ════════════════════════════════ tRPC ════════════════════════════════  */

export const RPC_SERVER_ENDPOINTS: Record<Environment | "remote", string> = {
  development: "/rpc",
  production: "/rpc",
  test: "/rpc",
  remote: "/rpc",
  local: "/rpc",
};

/** ════════════════════════════════ I18n ════════════════════════════════  */

/**
 * This is the source of truth for locales in all Fabric3 applications. There
 * should be no other places in this monorepo to obtain a list of supported
 * locales, countries, and languages.
 *
 */

/**
 * Locales we support across all our applications. Note the lowercase-UPPERCASE
 * format. There really isn't a 'standard' notation for locales (as far as
 * @nikhilanand could find). You'll see all manner of naming. Here are four
 * popular ones:
 *
 * - `en-us`
 * - `en-US`
 * - `en_us`
 * - `en_US`
 *
 * We just picked `en-US`.
 *
 * The User Service is the Source of Truth for the user's _preferred locale_
 * and it stores things as `en-us`. See the `standardizeLocale` function in the
 * `useUserLocale` module in the Core for how we enforce this.
 *
 * We can certainly decide upon something else in the future. But if we do,
 * modify that module when you modify this one :)
 */
export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];
export const DEFAULT_LOCALE = "en-US";
export const SUPPORTED_LOCALES = [
  "en-CA",
  "fr-CA",
  "pt-BR",
  DEFAULT_LOCALE,
] as const;

/**
 * Where we've deployed our web applications. For example,
 *
 * ```
 *    https://us.foo.granular.ag
 *            ^
 *    https://br.dev.bar.granular.ag
 *            ^
 * ```
 * These are in lowercase because the only context in which they're used
 * is the URI.
 */
export type SupportedCountry = (typeof SUPPORTED_COUNTRIES)[number];
export const DEFAULT_COUNTRY = "us";
export const SUPPORTED_COUNTRIES = [DEFAULT_COUNTRY, "br", "ca"] as const;

/**
 * Languages we support _independent_ of the countries specified by the
 * `SupportedCountry` type.
 */
export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];
export const DEFAULT_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = [DEFAULT_LANGUAGE, "fr", "pt"] as const;

export type UnitSystem = (typeof UNIT_SYSTEMS)[number];
export const UNIT_SYSTEMS = ["metric", "imperial"] as const;

/** ════════════════════════ Runtime Environments ════════════════════════ */

export const ENVIRONMENTS = [
  "local",
  "development",
  "production",
  "test",
] as const;

/**
 * These are the ONLY terms we use here. No more `granappdevelopment` or
 * `granapptest` or whatever. That's just silly.
 */
export type Environment = (typeof ENVIRONMENTS)[number];

/**
 * ════════════════════════════ Feature Flags ════════════════════════════
 *
 * We use the `Fabric3` project in LaunchDarkly for Feature Flags.
 *
 * Reference for keys (look for "Client-side ID"):
 * https://app.launchdarkly.com/settings/projects/fabric3/environments
 */

/**
 * LaunchDarkly keys that get embedded in the Container at build and get pushed
 * to the client.
 */
export const LAUNCH_DARKLY_KEYS: Record<Environment, string> = {
  local: "660ef0fc994eb40fe4b0d0a1",
  development: "660ef0fc994eb40fe4b0d0a1",
  test: "660ef09759ea420fca62687f",
  production: "660ef09759ea420fca626880",
} as const;

/**
 * We (the Architecture Team) use these keys for various scripts pertaining to
 * Monorepo maintenance and CI/CD tasks. If you're an FE/web dev, you will
 * never need them. They are NEVER to be embedded in ANY Frontend app!
 */
export const LAUNCH_DARKLY_SDK_KEYS: Record<Environment, string> = {
  local: "sdk-9c35eeb5-c731-474c-96d3-ad34aacd6c0e",
  development: "sdk-9c35eeb5-c731-474c-96d3-ad34aacd6c0e",
  test: "sdk-ba17b2c4-e36d-41ba-b858-34eb2c262fa4",
  production: "sdk-aa15154a-ca3c-4818-9fb5-1cd1ccd2dc86",
} as const;

export const CONTAINER_KILLSWITCH_FEATURE_FLAG = "container-enable";

/**
 * ═════════════════════════ Telemetry - DataDog RUM ═════════════════════════
 */

export const DATADOG_CLIENT_TOKENS: Record<Environment, string> = {
  local: "pub915bbe23b6f5ec952892353c5f5870af",
  development: "pub915bbe23b6f5ec952892353c5f5870af",
  test: "pub915bbe23b6f5ec952892353c5f5870af",
  production: "pub915bbe23b6f5ec952892353c5f5870af",
} as const;

export const DATADOG_APPLICATION_ID: Record<Environment, string> = {
  local: "33fd9306-54a1-44df-8ccb-25bbf94e1c82",
  development: "33fd9306-54a1-44df-8ccb-25bbf94e1c82",
  test: "33fd9306-54a1-44df-8ccb-25bbf94e1c82",
  production: "33fd9306-54a1-44df-8ccb-25bbf94e1c82",
} as const;

/**
 * ═══════════════════════════ Telemetry - Segment ═══════════════════════════
 *
 * NOTE: We don't care about the Test environment in Segment (for now at least)
 * and just use the same key as in Dev. This means that all Test events go to
 * the Dev 'source' in Segment. Both Product and QA have not told us that this
 * not is a problem (since 2019!) so let's not overthink it and make it one 🌸
 */

export const SEGMENT_KEYS: Record<Environment, string> = {
  local: "oz80YwiVQSmtvsTOwsDGXbQQgC9rztMB",
  development: "oz80YwiVQSmtvsTOwsDGXbQQgC9rztMB",
  test: "oz80YwiVQSmtvsTOwsDGXbQQgC9rztMB",
  production: "0bB9p4EvDSpEHIFMTZLeR8fad923J8Lk",
} as const;

/**
 * The key where the global analytics object will be accessible. Note that this
 * is on `window` (i.e., `window.analytics`). Yeah, I don't like it either but
 * let's do what the docs ask us to do and move on 🚀
 */
export const SEGMENT_OBJECT = "analytics";

/**
 * We send in the app name so we (developers) since all events go to the
 * same bucket that collects the old container's events and we need a way
 * to distinguish them.
 */
export const SEGMENT_APP_NAME = "fabric3";

/**
 * ═════════════════════════════════ Mapbox ══════════════════════════════════
 */
// We use the same key for all non-prod envs
const mapboxDevKey =
  // no-dd-sa
  "pk.eyJ1IjoiYWNyZXZhbHVlIiwiYSI6ImNrOWxpNmNzYTBvODUzZm1pMDcxbzlhMDAifQ.gcibAXAyxV982YKKmHJuJg";

/**
 * Mapbox API Key map by environment
 */
export const MAPBOX_ACCESS_TOKENS: Record<Environment, string> = {
  local: mapboxDevKey,
  development: mapboxDevKey,
  test: mapboxDevKey,
  production:
    // no-dd-sa
    "pk.eyJ1IjoiYWNyZXZhbHVlIiwiYSI6ImNrOWtnZ3FpcjA0NnIza211M2Y2bm1nemIifQ.YxJ_Uc52OkC2qE4oRyjuFw",
};

/**
 * Various time values so you don't make mistakes computing them. You could use
 * these, for example, when you specify invalidation rules in React Query.
 */
export const TIME_IN_MS = {
  MILLISECOND: 1,
  SECOND: 1_000,
  MINUTE: 60_000, // 1000 * 60
  HOUR: 3_600_000, // 1000 * 60 * 60
  DAY: 86_400_000, // 1000 * 60 * 60 * 24
  WEEK: 604_800_000, // 1000 * 60 * 60 * 24 * 7
  MONTH: 2_592_000_000, // 1000 * 60 * 60 * 24 * 30
  YEAR: 31_536_000_000, // 1000 * 60 * 60 * 24 * 365
};
